@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.heroin {
    width: 100%;
    height: 70vh;
    background: rgba(0, 0, 0, 0.171);
    position: relative;
  }
  
  .heroin:before {
    content: '';
    position: absolute;
    background: url('../../assets/addiction-submenu-bg.webp') no-repeat center top/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
  

.heroin {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.heroin .heroin-content {
    display: block;
    position: absolute;
    bottom: 30%;
    right: 5%;
    padding: 1rem;
}

.heroin h1 {
    font-size: 4rem;
    font-family: 'Montserrat', sans-serif;
    color: rgb(255, 255, 255);
    text-align: center;
}


/* MEDIA QUERIES */

@media screen and (max-width:940px) {

    .heroin .heroin-content {
        width: 100%;
        right: 0%;
    }

    .heroin h1 {
        font-size: 3rem;
    }

}