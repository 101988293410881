@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.InsuranceSection {
    width: 100%;
    position: relative;
    justify-content: center;
    padding: 1rem 0 6rem 0;

}
  
  .InsuranceSection:before {
    content: '';
    position: absolute;
    background: url('../../assets/hero-bg-1.jpg') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: 0.4;
  }


.InsuranceSection .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    width: 100%;
    padding: 2rem 0;
}

.InsuranceSection-container {
    text-align: center;
    padding: 25px;
}

.InsuranceSection-container p {
        padding: 1rem 0;
        font-family: 'Montserrat', sans-serif;
    }

.InsuranceSection h1 {
    padding: 1rem;
    font-family: 'Montserrat', sans-serif;
}

.insurance-thumbnail {
    width: 170px;
}

.insurance-thumbnail img {
    width: 100%;
}


@media  screen and (max-width:940px) {
    .InsuranceSection-container h1 {
        font-size: 1.5rem;
        padding: 1rem 0;
    }


    .InsuranceSection-container p {
        font-size: 14px;
    }

    .team img {
        width: 350px;
    }

    .insurance-thumbnail {
        width: 120px;
    }

}






