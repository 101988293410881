@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.ita {
    width: 100%;
    height: 70vh;
    background-color: rgba(0, 0, 0, 0.247);
    position: relative;
  }
  
  .ita:before {
    content: '';
    position: absolute;
    background: url('../../assets/addiction-treatment-bg.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
  

.ita {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.ita .ita-content {
    width: 40%;
    display: block;
    position: absolute;
    bottom: 20%;
    right: 5%;
    padding: 1rem;
}

.ita h1 {
    font-size: 3rem;
    font-family: 'Montserrat', sans-serif;
    color:white;
}

.ita h3 {
    color:white;
    font-size: 1rem;
    font-family: 'Montserrat', sans-serif;
}

/* MEDIA QUERIES */

@media screen and (max-width:940px) {

.ita .ita-content {
    width: 100%;
    display: block;
    position: absolute;
    bottom: 10%;
    left: 0;
    padding: 1rem;
    }

.ita h1 {
    font-size: 2rem;
    text-align: center;
}

.ita h3 {
    font-size: 1rem;
    text-align: center;
}
}