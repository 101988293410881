@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

/* wrap 1 */

.wrap1 {
    position: relative;
    background: url('../../assets/method-bg.jpg') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    padding: 10rem 0;
  }
  
  .wrap1-content {
    display: flex;
    justify-content: center;
  }

.wrap1 .left {
    background-color: rgba(6, 126, 156, 0.842);
    width: 40%;
    padding: 3rem 2rem;
    margin:2rem auto;
    border-radius: 1rem;
}

.wrap1 .right {
    width: 40%;
}

.wrap1 .left h1 {
    color: white;
    font-size: 1.2rem;
}

.wrap1 .left h2 {
    color: white;
    font-size: 3rem;
    line-height: 4rem;
    padding: 2rem 0 2rem 0;
}

.wrap1 .left p {
    color: white;
    text-align: left;
}

.wrap1 .left span {
    font-size: 20px;
    color: rgb(224, 155, 4);
}

.wrap1 .left ul li {
    color: white;
}


/* MEDIA QUERIES */

@media screen and (max-width:940px) {

    .wrap1 .left {
        width: 100%;
        margin: 1rem;
    }

    .wrap1 .right {
        display: none;
    }

    .wrap1 .left h2 {
        color: white;
        font-size: 2rem;
        line-height: 2.5rem;
        font-weight: normal;
    }

}

