@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.contact-hero {
    width: 100%;
    height: 60vh;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: rgba(24, 23, 23, 0.445);
  }
  
  .contact-hero:before {
    content: '';
    position: absolute;
    background: url('../../assets/contact-us.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }


.contact-hero-content {
    position: absolute;
    bottom: 25%;
    right: 5%;
    padding: 1rem;
}

.contact-hero h1 {
    font-size: 4rem;
    font-family: 'Montserrat', sans-serif;
    color: rgb(235, 241, 241);
    text-align: center;
}

.contact-hero p {
    font-size: 16px;
    color: white;
}

/* MEDIA QUERIES */

@media screen and (max-width:940px) {


.contact-hero h1 {
    font-size: 2rem;
}

.contact-hero p {
    font-size: 12px;
}

}