@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.privacyPolicy {
    width: 100%;
    height: 50vh;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: rgba(0, 0, 0, 0.719);
  }
  
  .privacyPolicy:before {
    content: '';
    position: absolute;
    background: url('../../assets/privacy_policy.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

  .privacyPolicyContent {
    display: block;
    position: absolute;
    bottom: 20%;
    padding: 1rem;
}

.privacyPolicyContent h1 {
    color: white;
    font-size: 3rem;
    font-weight: normal;
}

.privacyPolicyContent p {
    color: white;
    font-size: 16px;
    text-align: center;
}



/* MEDIA QUERIES - HERO */

@media screen and (max-width:940px) {

.privacyPolicyContent h1 {
    font-size: 2.5rem;
    text-align: center;
}

}


/* @media screen and (max-width:700px) {

  .privacyPolicyContent {
      height: 100vh;
  }

  
} */

