@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.navbar {
  position: absolute;
  width: 100%;
  z-index: 1000;
  transition: background 0.3s ease-in-out;
}

.navbar.homepage {
  background: transparent;
}

.navbar:not(.homepage) {
  background: white;
}

.navbar .navbar-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.navbar-logo {
  width: 120px;
  padding: 1rem;
  z-index: 2;
}

.navbar:not(.homepage) li a {
  color: black;
}

.navbar.homepage .icon {
  color: white;
}

.navbar:not(.homepage) .sub__menus__arrows svg, .sub__menus__arrows__full svg {
  color: rgb(0, 0, 0);
}


.navbar:not(.homepage) .icon {
  color: black;
}

.navbar-logo img {
  width: 100%;
  height: auto;
}

.navbar .icon {
  font-size: 1.5rem;
  color: rgb(255, 255, 255);
}

.navbar .logo .icon {
    font-size: 3rem;
    margin-right: 1rem;
    color: white;
}

.hamburger {
    display: none;
    padding: 1rem;
}

.navbar .nav-menu {
    height: 100%;
    align-items: center;
    display: flex;
    padding: 2rem 0;
}

.navbar li {
    width: max-content;
    font-family: 'Montserrat', sans-serif;
    padding: .5rem 1rem;
}

.navbar li a {
  font-size: 16px;
  font-weight: 500;
  transition: color 0.3s ease-in-out;
  text-decoration: none;
  color: white;
}

  .menu-item .sub__menus li a {
    color: #1d1b1b;
    font-size: 14px;
  }

  .menu-item .sub__menus li a:hover {
    color: rgb(255, 123, 0) !important;
  }


/* -----------------------------------------------------> SUB MENU */
.sub__menus {
    position: absolute;
    display: none;
    background: rgb(255, 255, 255);
    border: none;
    width: auto;
    margin-top: 0;
    z-index: 1000;
    border-radius: 5px;

  }

  /* -----------------------------------------------------> DROPDOWN ARROW */
  .sub__menus__arrows {
    position: relative;
  }

  .sub__menus__arrows svg, .sub__menus__arrows__full svg {
    padding-top: 0px;
    position: absolute;
    top: 11px;
    color: rgb(255, 255, 255);
  }


  
@media screen and (max-width: 1200px) {

  .sub__menus__arrows svg, .sub__menus__arrows__full svg {
    padding-top: 0px;
    position: absolute;
    top: 11px;
    color: rgb(0, 0, 0);
  }
  
    .navbar .hamburger {
        display: block;
        z-index: 15;
    }

    .navbar .nav-menu {
        position: absolute;
        flex-direction: column;
        width: 70%;
        height: 70vh;
        top: 100%;
        left:-100%;
        justify-content: center;
        background: white;
        transition: 0.5s ease-in;
    }

    .sub__menus {
      position: absolute;
      display: none;
      background: var(--primary-color);
      width: 100%;
      margin-top: 0;
      z-index: 1000;
      border-radius: 5px;
      left: 0;
    }

    .navbar li {
      width: 100%;
      font-family: 'Montserrat', sans-serif;
      padding: .5rem .7rem;
  }

    .navbar .active {
        left: 0;
    }

    .navbar li {
        font-size: 1rem;
    }

    .navbar li a {
      font-size: 1rem;
      color: rgb(0, 0, 0);
  }


    .navbar button {
        font-size: 1.8rem;
    }

    .menu-item .sub__menus li a {
        font-size: 16px;
        text-align: left;
        color: rgb(255, 255, 255);
      }

      .navbar .navbar-container {
        justify-content: space-between;
      }

      .navbar-logo {
        width: 130px;
      }

}





