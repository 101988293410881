@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Licorice&display=swap');


.hero {
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: rgba(24, 23, 23, 0.445);
  }
  
  .hero:before {
    content: '';
    position: absolute;
    background: url('../../assets/hero-bg-min.webp') no-repeat center top/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }


.hero .hero-container {
    position: absolute;
    padding: 1rem;
    bottom: 8%;
    width: 100%;
}

.hero-container .content p {
    font-size: 5rem;
    font-weight: 600;
    text-align: center;
    color: white;
    padding: 0 1rem;
    letter-spacing: 2px;
    font-family: 'Licorice', cursive;
    z-index: 9999999;
    animation: growText 10s ease-in-out infinite;
}

@keyframes growText {
    0% {
        transform: scale(0.8); /* Start slightly smaller */
    }
    50% {
        transform: scale(1.2); /* Grow larger */
    }
    100% {
        transform: scale(1); /* Return to normal size */
    }
}

/* MEDIA QUERIES */

@media screen and (max-width: 650px) {
    .hero-container .content p {
        font-size: 3rem;
    }
}
